import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfileImage from "../components/profile-image";

const IndexPage = ({data}) => {

  return (
  <Layout>
    <SEO title="Home" image={data.file.childImageSharp.fixed} keywords={
      [
        `tristan`,
        `farmer`,
        `java`,
        `graphql`,
        `developer`,
        `node`,
        `engineer`,
        `software`,
        `vim`
      ]
    } />
      <section className="hero is-primary diagonal-hero">
        <div className="hero-body ">
          <div className="container">
            <div className='columns'>
              <div className='column is-one-third'>
                <ProfileImage className='is-rounded'/>
              </div>
              <div className='column is-two-thirds'>
                <p className='title is-size-1-desktop is-size-3-touch'>
                  <span>Hi, I'm Tristan Farmer.</span>
                </p>
                <p className='subtitle is-size-2-desktop is-size-4-touch is-italic'>
                  <span>Senior Software Engineer</span>
                </p>
                <p className='is-size-4-desktop is-size-5-touch'>
				  Developer Experience Evangelist. Fluent in Java, Node, learning Go,
				  Vim, GraphQL, Build Tools, and I will dockerize your life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='diagonal-section'>
        <div className='container blog-container'>
          <div className='level'>
            <div className='level-item'>
              <h1 className='blog-title title is-2 is-centered'>Blog</h1>
            </div>
          </div>
          <div className='blog-list'>
            <ul>
              {data.allMarkdownRemark.edges.map(blogItem => {
                return (
                  <li key={blogItem.node.frontmatter.title} className='blog-list-item'>
                    <a className='title is-4' href={blogItem.node.frontmatter.path}>{blogItem.node.frontmatter.title}</a>
                    <p className='is-size-6 is-italic'>{blogItem.node.frontmatter.date} - {blogItem.node.timeToRead} minute read</p>
                    <p className='is-size-6'>{blogItem.node.frontmatter.description}</p>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>
  </Layout>
)}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { path: { regex: "/blog/" }}}, sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          frontmatter {
            title
            date
            path
            description
          }
          timeToRead
          excerpt
          fileAbsolutePath
        }
      }
    }
	file(relativePath: { eq: "tristan2020.jpg"}) {
	  childImageSharp {
		fixed(width: 600) {
		  src
		  width
		  height
        }
      }
    }
  }
`

export default IndexPage
